import { useMemo } from 'react'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { useAsyncTaskAxios } from 'react-hooks-async'
import { PreSignedUploadUrl } from '../types'
import { SyllabusStatusChangeReason } from '../enum'

export const useReopenSyllabus = (
  centreId: string,
  syllabusId: string,
  changeReason?: SyllabusStatusChangeReason
) => {
  const requestConfig = useMemo<AxiosRequestConfig>(
    () => ({
      url: `${process.env.REACT_APP_APIDOMAIN}/centres/${centreId}/syllabuses/${syllabusId}/reopen`,
      params: {
        changeReason,
      },
      method: 'PATCH',
    }),
    [centreId, syllabusId, changeReason]
  )

  const request = useAsyncTaskAxios<AxiosResponse<PreSignedUploadUrl>>(
    axios,
    requestConfig
  )

  const startRequest = () => {
    changeReason && request.start()
  }

  return {
    start: startRequest,
    inProgress: request.started && request.pending,
    result: request.result,
    status: request.result?.status,
    error: request.error,
  }
}

export default useReopenSyllabus
