import { ColumnMap, SortableColumnMap } from '../types'
import { parseToDate } from '../services'

export const SORTABLE_COLUMN: SortableColumnMap = {
  NAME: {
    title: 'Candidates (A-Z)',
    titleAlt: 'Candidates (Z-A)',
    sortable: true,
    comp: (opts) => (a, b) => {
      const diff = a.name.localeCompare(b.name)
      return diff === 0 ? SORTABLE_COLUMN.ULN.comp(opts)(a, b) : diff
    },
  },
  DOB: {
    title: 'DOB',
    sortable: true,
    comp: (opts) => (a, b) => {
      const diff = parseToDate(b.dob).getTime() - parseToDate(a.dob).getTime()
      return diff === 0 ? SORTABLE_COLUMN.NAME.comp(opts)(a, b) : diff
    },
  },
  ULN: {
    title: 'Candidate number',
    sortable: true,
    comp: () => (a, b) => b.candidateNumber?.localeCompare(a.candidateNumber),
  },
  GRADE: {
    title: 'School-assessed grades',
    sortable: true,
    comp: (opts) => (a, b) => {
      const diff =
        (a.grade ? opts.grading.indexOf(a.grade) : 1000) -
        (b.grade ? opts.grading.indexOf(b.grade) : 1000)
      return diff === 0 ? SORTABLE_COLUMN.NAME.comp(opts)(a, b) : diff
    },
  },
}

export const COLUMNS_DEFAULT: ColumnMap = {
  NAME: SORTABLE_COLUMN.NAME,
  GRADE: SORTABLE_COLUMN.GRADE,
}
