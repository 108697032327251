import React from 'react'
import { Col } from 'reactstrap'
import { MissingData } from '../../../common'

export const CandidateErrorInfo: React.FC<MissingData> = ({
  missingPredicted,
}) => {
  const showMissingPredictedError = missingPredicted > 0

  if (!showMissingPredictedError) {
    return null
  }

  return (
    <Col lg={12}>
      <div className="border rounded border-danger px-4 py-3">
        {showMissingPredictedError && (
          <li className=" text-danger font-weight-bold">
            {`${missingPredicted} candidates with missing school-assessed grades`}
          </li>
        )}
      </div>
    </Col>
  )
}
