import React from 'react'
import ResendInviteButton from './resend-invite-button/resend-invite-button'
import DeleteUserButton from './delete-user-button/delete-user-button'
import { Col, Label, Row } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/pro-solid-svg-icons'

import './user-row.scss'

interface Props {
  name: string
  surname: string
  email: string
  userId?: string
  role?: string
  centreId: string
  confirmed: boolean
  allowDelete: boolean
  onDelete: (email: string) => void
  className?: string
}

export const UserRow: React.FC<Props> = ({
  name,
  surname,
  email,
  confirmed,
  allowDelete,
  centreId,
  role,
  onDelete,
  userId,
  className = '',
}): JSX.Element => {
  return (
    <Row
      className={`${className} management-user align-items-center py-3 px-4`}
    >
      <Col>
        <Label className="d-block m-0 font-larger">
          {name} {surname}
        </Label>
        <Label className="d-block m-0 user-email">{email}</Label>
      </Col>
      {!confirmed && (
        <Col
          xs="auto"
          className="justify-content-center align-items-center d-flex"
        >
          <ResendInviteButton
            centreId={centreId}
            email={email}
            userId={userId}
          />
        </Col>
      )}
      <Col xs="auto" className="text-right noselect">
        <Label
          className={`status-label m-0 py-2 px-4 ${
            confirmed ? 'confirmed' : 'pending'
          }`}
        >
          {confirmed ? <FontAwesomeIcon className="mr-2" icon={faCheck} /> : ''}
          {confirmed ? 'Confirmed' : 'Pending'}
        </Label>
      </Col>{' '}
      {allowDelete && (
        <Col xs="auto" className="text-right noselect">
          <DeleteUserButton
            centreId={centreId}
            userId={userId}
            name={name}
            surname={surname}
            email={email}
            role={role}
            onDelete={onDelete}
          />
        </Col>
      )}
    </Row>
  )
}
