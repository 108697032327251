const ifMultiple = (n: number, x: any) => (n > 1 ? x : '')
const ss = (n?: number) => ifMultiple(n || 0, 's')

const DICTIONARY: { [key: string]: (number?: number) => string } = {
  GRADE_IS_INVALID_FOR_CANDIDATE: (n) =>
    `${n} grade${ss(n)} not valid for this syllabus.`,
  GRADE_IS_EMPTY: (n) => `${n} candidate${ss(n)} missing grade.`,
  DATA_CANNOT_BE_CHANGED: (n) =>
    `${n} candidate${ss(n)} details changed. Please check.`,
  DATA_WAS_NOT_EXPECTED: (n = 1) =>
    `${n} ${n > 1 ? 'entries' : 'entry'} not expected in this template.`,
  DUPLICATED_CANDIDATE: () =>
    'One or more candidates have the same candidate number',
  REQUIRED_FIELD: () => 'Missing data from required field(s).',
  INVALID_FILE_FORMAT: () =>
    'You have uploaded an invalid file format. Please upload as a CSV file.',
  WRONG_CENTRE_ID: () => 'Centre is incorrect.',
  WRONG_SYLLABUS_ID: () => `The syllabus ID is incorrect.`,
  SYLLABUS_CANNOT_BE_EDITED: () => `This syllabus has already been submitted.`,
}

const getTextFromError = (key: string, number?: number): string =>
  DICTIONARY[key] ? DICTIONARY[key](number) : key

export default getTextFromError
