import React from 'react'
import { Col, Container, Row } from 'reactstrap'

import ciIntroContent from '../../introContent.md'
import ReactMarkdown from 'react-markdown'
import { isHOC, useAuth0 } from '../../../../common'

interface Props {
  centreId: string
}

export const CentreSyllabusPageHeader: React.FC<Props> = ({
  centreId,
}): JSX.Element => {
  const { user } = useAuth0()

  return (
    <div className="bg-light py-45 px-3 mb-5">
      <Container>
        <Row className="mb-1">
          <Col lg={12}>
            <div
              className="markdown-content"
              data-testid="centre-syllabus-intro-content"
            >
              <ReactMarkdown source={ciIntroContent} />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            {user && isHOC(user) ? (
              <strong>
                If you need to make any changes after you have submitted your
                grades, please contact us.
              </strong>
            ) : (
              <ul className="list-inline font-weight-bold ">
                <li className="list-inline-item mr-5">
                  <span className="mr-2">&bull;</span> Download or upload
                  templates
                </li>
                <li className="list-inline-item mr-5">
                  <span className="mr-2">&bull;</span> Enter or edit grades
                </li>
                <li className="list-inline-item mr-3">
                  <span className="mr-2">&bull;</span> Send for review and
                  approval
                </li>
              </ul>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  )
}
