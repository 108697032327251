import { useMemo } from 'react'
import { isFuture, isPast } from 'date-fns/esm'
import { useAuth0 } from '../services'
import { SystemAvailabilityEnum } from '../enum'
import { useConfig } from './use-config'

export const useAvailability = () => {
  const {
    config,
    systemAvailabilityConfigLoaded: systemAvailabilityLoaded,
  } = useConfig()
  const { user, isAuthenticated } = useAuth0()

  const {
    systemAvailability,
    captureAvailableFrom,
    captureAvailableTo,
  } = config

  const userManagamentAvailable = useMemo(
    () =>
      systemAvailability === SystemAvailabilityEnum.AVAILABLE &&
      captureAvailableFrom &&
      isPast(captureAvailableFrom) &&
      captureAvailableTo &&
      isFuture(captureAvailableTo),

    [systemAvailability, captureAvailableFrom, captureAvailableTo]
  )

  const gradingAvailable = useMemo(
    () =>
      systemAvailability === SystemAvailabilityEnum.AVAILABLE &&
      ((captureAvailableFrom &&
        captureAvailableTo &&
        isPast(captureAvailableFrom) &&
        isFuture(captureAvailableTo)) ||
        !systemAvailabilityLoaded),
    [
      systemAvailability,
      captureAvailableFrom,
      captureAvailableTo,
      systemAvailabilityLoaded,
    ]
  )

  const userLoaded = user && isAuthenticated

  return {
    userManagamentAvailable,
    gradingAvailable,
    systemAvailabilityLoaded,
    userLoaded,
  }
}
