import React, { FC, useEffect, useMemo, useState } from 'react'
import axios from 'axios'
import Route from '../route/route'
import { Switch } from 'react-router'
import { Redirect } from 'react-router-dom'
import { Container } from 'reactstrap'
import { SubjectPage } from '../subject-page'
import { NavBar } from '../navbar'
import { HomePage } from '../home'
import { PrivateRoute } from '../private-route'
import { CentreSyllabusPageWithRouteAndExpiration } from '../centre-syllabus-page'
import {
  ManageAdminsPageWithRouteAndExpiration,
  ManageSignOffPageWithExpiration,
  ManageSignOffPageWithRouteAndExpiration,
  ManageTeachersPageWithExpiration,
  ManageTeachersPageWithRouteAndExpiration,
} from '../pages'
import {
  BackButtonProvider,
  isAdmin,
  isCAAdmin,
  Loading,
  NotFound,
  ROLES,
  SystemAvailabilityEnum,
  useAuth0,
  useConfig,
  useExpiryPage,
  useTracking,
} from '../../common'
import { isFuture, isPast } from 'date-fns'
import ServerErrorHoldingPage from '../holding-page/server-error-holding-page'
import HoldingPage from '../holding-page/holding-page'
import { CambridgeAdminControls } from '../ca-admin-controls'
import { HelpAndContact } from '../help-and-contact'
import { ExpiryHoldingPage } from '../holding-page'

export const RoutesWithHeader: FC = (): JSX.Element => {
  useTracking(process.env.REACT_APP_GA_TRACKING_CODE)

  const { user, currentCentre, isAuthenticated } = useAuth0()
  const {
    config,
    systemAvailabilityConfigLoaded,
    loadSystemAvailabilityConfig,
  } = useConfig()

  const [
    captureAvailabilityClosed,
    captureAvailabilityExpired,
  ] = useExpiryPage()
  const [serverError, setServerError] = useState<any | null>(null)

  const captureIsClosed =
    captureAvailabilityClosed || captureAvailabilityExpired

  useEffect(() => {
    axios.interceptors.response.use(undefined, function (error) {
      if (`${error?.response?.status}`.startsWith('5')) {
        // 5xx error code
        setServerError(error)
      }
      return Promise.reject(error)
    })
  }, [])

  useEffect(() => {
    if (config.systemAvailability === SystemAvailabilityEnum.SYSTEM_ERROR) {
      setServerError(SystemAvailabilityEnum.SYSTEM_ERROR)
    }
  }, [config.systemAvailability])

  const rules = useMemo(() => {
    const { captureAvailableFrom, captureAvailableTo } = config

    const centres =
      (captureAvailableFrom &&
        captureAvailableTo &&
        isPast(captureAvailableFrom) &&
        isFuture(captureAvailableTo)) ||
      !systemAvailabilityConfigLoaded

    const manageUser =
      (captureAvailableTo && isFuture(captureAvailableTo)) ||
      !systemAvailabilityConfigLoaded

    return {
      centres,
      manageUser,
    }
  }, [config, isAuthenticated, user])

  useEffect(() => {
    if (isAuthenticated) {
      loadSystemAvailabilityConfig()
    }
  }, [isAuthenticated])

  const renderedContent = useMemo(() => {
    if (serverError) {
      return <ServerErrorHoldingPage />
    }
    if (!config.siteEnabled) {
      return <HoldingPage />
    }
    if (!config.systemAvailability) {
      return (
        <Container>
          <Loading className="mt-5 d-block mx-auto" />
        </Container>
      )
    }
    if (config.systemAvailability === SystemAvailabilityEnum.CAPTURE_ERROR) {
      return (
        <PrivateRoute>
          <ServerErrorHoldingPage />;
        </PrivateRoute>
      )
    }
    return (
      <Switch>
        <Route exact path="/">
          <PrivateRoute>
            <HomePage />
          </PrivateRoute>
        </Route>

        <Route exact path="/centres">
          <PrivateRoute roles={[ROLES.QA_Super, ROLES.CA_Admin]}>
            <CambridgeAdminControls />
          </PrivateRoute>
        </Route>
        {!rules.centres && user && isCAAdmin(user) && (
          <Redirect
            exact
            from="/centres/:id"
            to="/centres/:id/manage/teachers"
          />
        )}
        {!rules.centres && rules.manageUser && user && isAdmin(user) && (
          <Redirect exact from="/centres/:id" to="/manage/teachers" />
        )}
        {rules.centres && [
          <Route key="centres-1" exact path="/centres/:id">
            <PrivateRoute>
              <CentreSyllabusPageWithRouteAndExpiration
                isExpired={captureIsClosed}
              />
            </PrivateRoute>
          </Route>,
          <Route key="centres-2" exact path="/centres/:id/syllabus/:syllabusId">
            <PrivateRoute>
              <SubjectPage />
            </PrivateRoute>
          </Route>,
        ]}

        {rules.manageUser && [
          <Route exact path="/manage/teachers">
            <PrivateRoute roles={[ROLES.Admin]}>
              {currentCentre && (
                <ManageTeachersPageWithExpiration
                  centreId={currentCentre}
                  isExpired={captureIsClosed}
                />
              )}
            </PrivateRoute>
          </Route>,
          <Route exact path="/manage/signoff">
            <PrivateRoute roles={[ROLES.Admin]}>
              {currentCentre && (
                <ManageSignOffPageWithExpiration
                  centreId={currentCentre}
                  isExpired={captureIsClosed}
                />
              )}
            </PrivateRoute>
          </Route>,
        ]}

        <Route exact path="/centres/:id/manage/teachers">
          <PrivateRoute roles={[ROLES.QA_Super, ROLES.CA_Admin]}>
            {user && (
              <ManageTeachersPageWithRouteAndExpiration
                isExpired={captureIsClosed}
              />
            )}
          </PrivateRoute>
        </Route>
        <Route exact path="/centres/:id/manage/signoff">
          <PrivateRoute roles={[ROLES.QA_Super, ROLES.CA_Admin]}>
            {user && (
              <ManageSignOffPageWithRouteAndExpiration
                isExpired={captureIsClosed}
              />
            )}
          </PrivateRoute>
        </Route>
        <Route exact path="/centres/:id/manage/admins">
          <PrivateRoute roles={[ROLES.QA_Super, ROLES.CA_Admin]}>
            {user && (
              <ManageAdminsPageWithRouteAndExpiration
                isExpired={captureIsClosed}
              />
            )}
          </PrivateRoute>
        </Route>

        <Route exact path="/help-and-contact">
          <PrivateRoute>
            <HelpAndContact />
          </PrivateRoute>
        </Route>

        {captureIsClosed && <Route component={ExpiryHoldingPage} />}

        <Route component={NotFound} />
      </Switch>
    )
  }, [
    captureAvailabilityExpired,
    config.siteEnabled,
    config.systemAvailability,
    currentCentre,
    rules.centres,
    rules.manageUser,
    serverError,
    user,
  ])

  return (
    <div style={{ marginBottom: '5rem' }}>
      <BackButtonProvider>
        <NavBar />
        {renderedContent}
      </BackButtonProvider>
    </div>
  )
}
