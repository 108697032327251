import React, { useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { faHeartbeat } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useExpiryPage } from '../../common'
import captureAvailabilityExpiredContent from './capture-available-to-expired-content.md'
import captureClosedContent from './capture-closed-content.md'

export const ExpiryHoldingPage: React.FC = (): JSX.Element => {
  const [
    captureAvailabilityClosed,
    captureAvailabilityExpired,
  ] = useExpiryPage()
  const [source, setSource] = useState<string>()

  useEffect(() => {
    if (captureAvailabilityClosed) {
      setSource(captureClosedContent)
    } else if (captureAvailabilityExpired) {
      setSource(captureAvailabilityExpiredContent)
    }
  }, [captureAvailabilityClosed, captureAvailabilityExpired])

  return (
    <div className="container">
      <div className="holding-page w-100 align-items-center bg-light">
        <FontAwesomeIcon icon={faHeartbeat} size="2x" />
        <ReactMarkdown source={source} />
      </div>
    </div>
  )
}
