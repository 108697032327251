import React, { useCallback, useEffect, useMemo, useState } from 'react'
import ReactGA from 'react-ga'
import useReactRouter from 'use-react-router'
import { Button, Col, ModalProps, Row, Spinner } from 'reactstrap'
import {
  CentreSyllabus,
  getSubTitle,
  InlineErrorMessageNoBorder,
  PathParam,
  ProjectModal,
  RsModalBody,
  RsModalHeader,
  Title,
  useApproveSyllabus,
} from '../../../../../common'
import {
  faCheck,
  faExclamationTriangle,
  faPaperPlane,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const ApproveModal: React.FC<ModalProps & CentreSyllabus> = ({
  setSyllabusCallback,
  syllabusName,
  qualification,
  totalCandidates,
  id,
  qualLevel,
  syllabusCode,
  dataSource,
  isOpen,
  isOpenCallback,
}) => {
  const { match } = useReactRouter<PathParam>()

  const [showError, setShowError] = useState(false)

  useEffect(() => {
    if (isOpen) {
      setShowError(false)
    }
  }, [isOpen])

  const approvalSyllabusUrl = useMemo(
    () =>
      `${process.env.REACT_APP_APIDOMAIN}/centres/${match.params.id}/syllabuses/${match.params.syllabusId}`,
    [match.params.id, match.params.syllabusId]
  )

  const { start, result, error, inProgress } = useApproveSyllabus(
    approvalSyllabusUrl
  )

  const onConfirm = useCallback(() => {
    ReactGA.event({
      category: 'Grade Submission',
      action: 'Submit',
      label: id,
    })
    start()
  }, [id, start])

  const onContinue = useCallback(() => {
    !!result && setSyllabusCallback(result)
    isOpenCallback(!isOpen)
  }, [isOpenCallback, result, setSyllabusCallback, isOpen])

  const onClose = useCallback(() => isOpenCallback(!isOpen), [
    isOpenCallback,
    isOpen,
  ])

  useEffect(() => {
    if (error) {
      setShowError(true)
    } else {
      setShowError(false)
    }
  }, [error])

  const headNotFound = useMemo(() => error && error.includes('HoC not found'), [
    error,
  ])

  const alreadySubmitted = useMemo(
    () => error && error.includes('Invalid valuation status: submitted'),
    [error]
  )

  return (
    <ProjectModal centered isOpen={isOpen} dataTestid="approval-modal">
      <RsModalHeader toggle={onContinue} className="bg-white px-5 pt-5 pb-25">
        <Title
          title={syllabusName}
          subTitle={getSubTitle(
            qualification,
            syllabusCode,
            qualLevel,
            dataSource
          )}
          ancillery={`${totalCandidates} candidates`}
          titleColor="success"
        />
      </RsModalHeader>
      <RsModalBody className="pt-4 px-5 pb-5 rounded-bottom">
        {result && (
          <div>
            <Row className="align-items-center">
              <Col>
                <span className="font-weight-bold font-larger">
                  Your data has been sent for approval
                </span>
                <FontAwesomeIcon icon={faCheck} className="ml-2" fixedWidth />
              </Col>
            </Row>
            <p>
              Please wait while your Head of Centre reviews and approves your
              school-assessed grades for this subject. You can still view your
              data from the Dashboard.{' '}
              <span style={{ textDecoration: 'underline' }}>
                You now cannot make any changes.
              </span>
            </p>
            <Button
              color="e4"
              onClick={onContinue}
              data-testid="approval-modal-continue-btn"
            >
              Continue
            </Button>
          </div>
        )}
        {alreadySubmitted && (
          <div>
            <p className="font-weight-bold">
              Students data already submitted. Please wait for Head of Centre
              for further action.
            </p>
            <Button
              color="e4"
              onClick={onClose}
              data-testid="approval-modal-close-btn"
            >
              Close
            </Button>
          </div>
        )}
        {showError && headNotFound && (
          <div>
            <Row className="align-items-center mb-2">
              <Col>
                <span className=" font-weight-bold font-larger text-danger">
                  The system could not find a valid Head of centre
                </span>
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  className="ml-2 text-danger"
                  fixedWidth
                />
              </Col>
            </Row>
            <p>
              Please contact your Centre Administrator. Currently there is no
              Head of Centre set up. Once a Head of Centre is set up, you will
              be able to send your syllabus for approval.
            </p>
            <Button
              color="e4"
              onClick={onClose}
              data-testid="approval-modal-close-btn"
            >
              Close
            </Button>
          </div>
        )}
        {!alreadySubmitted && !(showError && headNotFound) && !result && (
          <div>
            <p className="font-weight-bold font-larger">Send for approval</p>
            <p className="font-normal">
              You are about to send your school-assessed grades for this subject
              to your Head of Centre to approve.{' '}
              <u>
                You will not be able to make any changes once you have sent them
                for approval.
              </u>{' '}
              Are you sure you wish to send them?
            </p>
            {showError && error && (
              <InlineErrorMessageNoBorder title="Send for approval failed, please try again and if the problem persists contact your system administrator" />
            )}
            <Row className="mt-5 ">
              <Col xs="auto">
                <Button
                  color="e4"
                  onClick={onClose}
                  data-testid="approval-modal-cancel-btn"
                >
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button
                  onClick={onConfirm}
                  color="success"
                  data-testid="approval-modal-confirm-btn"
                >
                  {inProgress && <Spinner size="sm" className="mr-2" />}
                  {!inProgress && (
                    <FontAwesomeIcon
                      icon={faPaperPlane}
                      className="mr-2"
                      fixedWidth
                    />
                  )}
                  Confirm and Send
                </Button>
              </Col>
            </Row>
          </div>
        )}
      </RsModalBody>
    </ProjectModal>
  )
}
