import React from 'react'
import { ManagementPage } from './management-page'
import { ROLES, withCentreId, withExpiration } from '../../common'

let INFO_DESCRIPTION = <></>

if (process.env.REACT_APP_BU === 'ci') {
  INFO_DESCRIPTION = (
    <>
      The Head of Centre is responsible for reviewing and approving
      school-assessed grades before submitting them to Cambridge International.
      They will also need to complete a <b>Head of Centre declaration.</b>
      <br />
      Once you have added your Head of Centre, they will receive a confirmation
      email with a verification link. They will need to click the link to verify
      their account before they can review and approve{' '}
      <b>school-assessed grades.</b>
    </>
  )
}

export const ManageSignOffPage: React.FC<{ centreId: string }> = ({
  centreId,
}): JSX.Element => (
  <ManagementPage
    title="Set up Head of Centre"
    centreId={centreId}
    description={INFO_DESCRIPTION}
    buttonText="Add Head of Centre"
    emptyListMessage="No head of centre has been assigned"
    role={ROLES.HOC}
    currentUsersLabel="Current Head of Centre"
  />
)

export const ManageSignOffPageWithRoute = withCentreId(ManageSignOffPage)
export const ManageSignOffPageWithExpiration = withExpiration(ManageSignOffPage)
export const ManageSignOffPageWithRouteAndExpiration = withExpiration(
  ManageSignOffPageWithRoute
)
