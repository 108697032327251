import React from 'react'
import {
  ValuationStatus,
  isAdmin,
  isCAAdmin,
  isHOC,
  isTeacher,
  User,
} from '../../../common'
import { DownloadTemplateButton } from './download-template-button'
import { SyllabusReopenButton } from '../../syllabus-reopen-button'
import { DeadlineButton } from './deadline-button'
import { SyllabusProgressStatus } from './syllabus-progress-status'
import { SyllabusSubmissionStatus } from './syllabus-submission-status'
import { adjustTimestampToUtc } from '../../candidates-list/constants'

export interface Props {
  title: string
  subTitle: string
  syllabusId: string
  centreId: string
  totalCandidates: number
  gradedCandidates: number
  inProgress: boolean
  user: User | undefined
  syllabusTemplateFileName: string
  closed: boolean
  is: (status: string) => boolean
  onUpdate?: () => void
  deadline: number
  extendedDeadline: number
}
export const StatusAndControlBlock: React.FC<Props> = ({
  title,
  subTitle,
  syllabusId,
  centreId,
  totalCandidates,
  gradedCandidates,
  inProgress,
  user,
  syllabusTemplateFileName,
  closed = false,
  is,
  onUpdate,
  deadline,
  extendedDeadline,
}) => {
  return (
    <>
      <SyllabusProgressStatus
        syllabusId={syllabusId}
        inProgress={inProgress}
        closed={closed}
        is={is}
      />
      {user && (isAdmin(user) || isTeacher(user) || isHOC(user)) && (
        <DownloadTemplateButton
          centreId={centreId}
          id={syllabusId}
          fileName={syllabusTemplateFileName}
        />
      )}
      {!closed && user && isCAAdmin(user) && is(ValuationStatus.APPROVED) && (
        <SyllabusReopenButton
          btnText="Status"
          centreId={centreId}
          header={title}
          subheader={subTitle}
          ancillery={`${totalCandidates} candidates`}
          syllabusId={syllabusId}
          gradedCandidates={gradedCandidates}
          totalCandidates={totalCandidates}
          syllabusReopened={() => onUpdate && onUpdate()}
        />
      )}
      {user && isCAAdmin(user) && (
        <DeadlineButton
          header={title}
          subheader={subTitle}
          ancillery={`${totalCandidates} candidates`}
          centreId={centreId}
          syllabusId={syllabusId}
          onUpdate={() => onUpdate && onUpdate()}
          deadline={adjustTimestampToUtc(deadline)}
          extendedDeadline={adjustTimestampToUtc(extendedDeadline)}
        />
      )}
      <SyllabusSubmissionStatus
        inProgress={inProgress}
        closed={closed}
        user={user}
        is={is}
        data-testid={`syllabus-progress-submission-status-${syllabusId}`}
      />
    </>
  )
}
