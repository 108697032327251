import { faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useMemo } from 'react'
import { Col, Label, Row } from 'reactstrap'
import {
  CentreSyllabus,
  ColumnMap,
  COLUMNS_DEFAULT,
  SORTABLE_COLUMN,
  TextAlign,
} from '../../../../common'
import { candidateColumnSizes } from '../../constants'

import './candidates-sorter.scss'

export interface SortCellProps {
  title: string
  toggle: VoidFunction
  textAlign: TextAlign
  upDownActive: string
  sortable: boolean
  size?: number
}

export interface CandidatesSorterProps {
  currentSorting: string
  filter: string
  showBottomBorder?: boolean
  toggleSorting: (f: string) => void
  syllabus: CentreSyllabus
}

const SortCell: React.FC<SortCellProps> = ({
  title,
  toggle,
  textAlign,
  upDownActive,
  sortable,
  size,
}): JSX.Element => {
  const inactiveColor = {
    color: '#d4d4d4',
  }

  const iconProps = {
    up: upDownActive === 'up' ? {} : inactiveColor,
    down: upDownActive === 'down' ? {} : inactiveColor,
  }

  return (
    <Col
      className={`text-${textAlign}`}
      md={size}
      data-testid="candidates-sorter"
    >
      <Label
        onClick={toggle}
        className={`sorter-label noselect text-primary bg-none px-0 my-0 ${
          sortable ? 'sortable' : ''
        }`}
      >
        {title}

        {sortable && (
          <span>
            <FontAwesomeIcon
              icon={faSortUp}
              {...iconProps.up}
              className={`sort-up ml-2`}
            />
            <FontAwesomeIcon
              icon={faSortDown}
              {...iconProps.down}
              className={`ml-2`}
            />
          </span>
        )}
      </Label>
    </Col>
  )
}

export const CandidatesSorter: React.FC<CandidatesSorterProps> = ({
  currentSorting,
  filter,
  showBottomBorder,
  toggleSorting,
}): JSX.Element => {
  const sorting: ColumnMap = useMemo(
    () => ({
      ...(filter === 'ALL' ? COLUMNS_DEFAULT : SORTABLE_COLUMN),
    }),
    [filter]
  )

  const [key, direction] = currentSorting.split('-')
  const alignSortCellText = (index: number): TextAlign => {
    if (index === 0) return TextAlign.LEFT
    else return TextAlign.CENTER
  }

  return (
    <Row
      className={`sorter-container px-25 py-25 ${
        showBottomBorder ? 'border-bottom' : ''
      }`}
    >
      {Object.keys(sorting).map((it, idx) => {
        const title =
          direction === 'up' && key === it
            ? sorting[it].titleAlt || sorting[it].title
            : sorting[it].title

        const upDownActive = key === it ? direction : ''

        return (
          <SortCell
            key={`candidate-sorter-${it}`}
            title={title}
            textAlign={alignSortCellText(idx)}
            upDownActive={upDownActive}
            sortable={!!sorting[it].sortable}
            size={
              (candidateColumnSizes[Object.keys(sorting).length] || [])[idx]
            }
            toggle={() => sorting[it].sortable && toggleSorting(it)}
          />
        )
      })}
    </Row>
  )
}
