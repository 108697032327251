import React from 'react'
import { Container } from 'reactstrap'
import { CentreSyllabus } from '../../../../common'
import { SubjectPageHeaderEditMode, SubjectPageHeaderReadOnly } from './headers'

interface Props {
  syllabus: CentreSyllabus | undefined
  viewOnly: boolean
  showButtons: boolean
  approvalDisabled: boolean
  viewOnlyState: string
  onUploadComplete: (uploadedWithErr: boolean) => void
  setSyllabusCallback: (syllabus: CentreSyllabus | undefined) => void
}

export const SubjectPageHeader: React.FC<Props> = ({
  syllabus,
  viewOnly,
  showButtons,
  approvalDisabled,
  viewOnlyState,
  onUploadComplete,
  setSyllabusCallback,
}) => {
  return (
    <>
      <Container>
        {syllabus && !viewOnly && (
          <SubjectPageHeaderEditMode
            syllabus={syllabus}
            showButtons={showButtons}
            approvalDisabled={approvalDisabled}
            viewOnly={viewOnly}
            onUploadComplete={onUploadComplete}
            setSyllabusCallback={setSyllabusCallback}
          />
        )}

        {syllabus && viewOnly && (
          <SubjectPageHeaderReadOnly
            viewOnlyState={viewOnlyState}
            syllabus={syllabus}
          />
        )}
      </Container>
    </>
  )
}
