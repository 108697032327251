import React from 'react'
import { Col, Button } from 'reactstrap'
import {
  faEye,
  faCheckCircle,
  faClock,
} from '@fortawesome/pro-regular-svg-icons'
import { faPaperPlane, faPen } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  User,
  ValuationStatus,
  isTeacher,
  isHOC,
  isAdmin,
} from '../../../common'

interface Props {
  inProgress: boolean
  closed: boolean
  user: User | undefined
  is: (status: string) => boolean
}

export const SyllabusSubmissionStatus: React.FC<Props> = ({
  inProgress,
  closed,
  user,
  is,
}) => {
  return (
    <>
      {inProgress && !is(ValuationStatus.SUBMITTED) && (
        <Col xs="auto" className="text-right pr-3">
          {user &&
          (isTeacher(user) || isHOC(user) || isAdmin(user)) &&
          !closed ? (
            <Button color="success" className="text-uppercase">
              <FontAwesomeIcon
                icon={is(ValuationStatus.COMPLETE) ? faPaperPlane : faPen}
                className="mr-2 font-normal"
              />
              <span className="font-normal">
                {is(ValuationStatus.COMPLETE) ? 'Send' : 'Edit'}
              </span>
            </Button>
          ) : (
            <Button color="success" className="text-uppercase">
              <FontAwesomeIcon icon={faEye} className="mr-2 font-normal" />
              <span className="font-normal">View</span>
            </Button>
          )}
        </Col>
      )}
      {((user && isHOC(user) && is(ValuationStatus.SUBMITTED)) ||
        is(ValuationStatus.APPROVED)) && (
        <Col xs="auto" className="text-right pr-3">
          <div className="syllabus-progress-notification text-uppercase rounded font-weight-bold px-3 py-2 text-white bg-success">
            <FontAwesomeIcon
              className="mr-2"
              icon={is(ValuationStatus.SUBMITTED) ? faEye : faCheckCircle}
            />
            <span>
              {is(ValuationStatus.SUBMITTED)
                ? closed
                  ? 'VIEW'
                  : 'VIEW AND APPROVE'
                : 'APPROVED AND SUBMITTED'}
            </span>
          </div>
        </Col>
      )}
      {!(user && isHOC(user)) && is(ValuationStatus.SUBMITTED) && (
        <Col xs="auto" className="text-right pr-3">
          <div className="syllabus-progress-notification text-uppercase rounded font-weight-bold px-3 py-2 text-white bg-primary">
            <FontAwesomeIcon className="mr-2" icon={faClock} />
            AWAITING APPROVAL
          </div>
        </Col>
      )}
    </>
  )
}
