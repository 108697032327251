import { MissingData, SortableColumn, SyllabusCandidate } from '../types'
import { SORTABLE_COLUMN } from '../constants'
import { countBy } from './util'

export const toggleSorting = (
  sorting: string,
  currentSorting: string
): string => {
  const [curr, direction] = currentSorting.split('-')
  const invDirection = direction === 'up' ? 'down' : 'up'
  return sorting !== curr ? `${sorting}-down` : `${curr}-${invDirection}`
}

export const candidatesSorting = (
  candidates: SyllabusCandidate[],
  sorting: string,
  options: { grading: string[] }
): string[] => {
  const [sortingKey, direction] = sorting.split('-')
  const { comp } = SORTABLE_COLUMN[sortingKey] as SortableColumn
  const sorted = [...candidates].sort(comp(options)).map((it) => it.id)

  if (sortingKey === 'GRADE') {
    let arrToChange = [...candidates].sort(comp(options))
    let grades = countBy(arrToChange, (x) => x.grade)
    let res: string[] = []
    let ungradedStudents = arrToChange
      .filter((d) => d.grade === undefined)
      .map((e) => e.id)
    Object.keys(grades).forEach((x) => {
      let sortedIds = arrToChange.filter((y) => y.grade === x).map((e) => e.id)
      direction === 'up'
        ? sortedIds.forEach((id) => res.unshift(id))
        : sortedIds.forEach((id) => res.push(id))
    })
    direction === 'up'
      ? (res = ungradedStudents.concat(res))
      : (res = res.concat(ungradedStudents))
    return res
  }
  return direction === 'up' ? sorted.reverse() : sorted
}

export const candidatesFiltering = (
  candidates: SyllabusCandidate[],
  filter: string
): string[] =>
  (filter === 'ALL'
    ? candidates
    : candidates.filter((it) => it.grade === filter)
  ).map((it) => it.id)

export const buildGradingMap = (
  candidates: SyllabusCandidate[]
): { [key: string]: number } =>
  candidates
    .map((it) => it.grade)
    .filter((x) => x)
    .reduce(
      (acc, curr) => ({
        ...acc,
        // @ts-ignore
        [curr]: (acc[curr] || 0) + 1,
      }),
      {}
    )

export const applySearchFilter = (
  candidates: SyllabusCandidate[],
  filter: string
): SyllabusCandidate[] => {
  const filt = filter.toLowerCase()
  return candidates.filter(
    (c) =>
      c.name.toLowerCase().includes(filt) ||
      c.candidateNumber.replace(/^0+/, '').toLowerCase().startsWith(filt)
  )
}

export const hasErrors = (missingData: MissingData): boolean =>
  missingData.missingPredicted > 0

export const getErrorCounts = (
  candidates: SyllabusCandidate[]
): MissingData => ({
  missingPredicted: candidates.filter((candidate) => !candidate.grade).length,
})

export const isGraded = (candidate: SyllabusCandidate) => !candidate.grade
