import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowToTop } from '@fortawesome/pro-solid-svg-icons'
import { Button } from 'reactstrap'
import { ManageUploadModal } from '../../../upload'
import { CentreSyllabus } from '../../../../common'

interface Props {
  syllabus: CentreSyllabus
  onUploadComplete: (uploadedWithErr: boolean) => void
}

export const UploadTemplateButton: React.FC<Props> = ({
  syllabus,
  onUploadComplete,
}) => {
  const [uploadModalShow, setUploadModalShow] = useState(false)
  return (
    <>
      <Button
        color="e4"
        className="mr-3 text-secondary text-center"
        onClick={(e) => {
          e.preventDefault()
          setUploadModalShow(true)
        }}
        data-testid="syllabus-page-csv-template-upload-button"
      >
        <FontAwesomeIcon icon={faArrowToTop} fixedWidth />
        <div>Upload Template</div>
      </Button>
      {syllabus && (
        <ManageUploadModal
          onUploadComplete={(uploadedWithErr) => {
            setUploadModalShow(false)
            onUploadComplete(uploadedWithErr)
          }}
          onClose={() => {
            setUploadModalShow(false)
          }}
          {...syllabus}
          isOpen={uploadModalShow}
        />
      )}
    </>
  )
}
