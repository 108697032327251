import React from 'react'
import useReactRouter from 'use-react-router'
import {
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavItem,
  Row,
  UncontrolledDropdown,
} from 'reactstrap'
import { Link, NavLink, useHistory } from 'react-router-dom'
import { isAdmin, useAuth0, PathParam, useAvailability } from '../../common'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCaretDown,
  faPowerOff,
  faUsers,
  faUserShield,
} from '@fortawesome/free-solid-svg-icons'
import { BackLink } from '../back-link'

import './navbar.scss'

export const NavBar = (): JSX.Element => {
  const { logout, user, currentCentre } = useAuth0()
  const { location } = useReactRouter<PathParam>()
  const history = useHistory()

  const {
    userManagamentAvailable,
    systemAvailabilityLoaded,
    userLoaded,
  } = useAvailability()

  const isLandingPage = location.pathname === '/'
  const sysAvailabilityConfigAndUserLoaded =
    systemAvailabilityLoaded && userLoaded

  return (
    <div className="nav-container">
      <Navbar color="faded" className="py-3" light expand="md">
        <Container>
          <Row className="w-100 align-items-center justify-content-end no-gutters">
            {!isLandingPage && (
              <Col>
                <BackLink />
              </Col>
            )}
            <Col
              sm={sysAvailabilityConfigAndUserLoaded ? '2' : '10'}
              className={`${
                sysAvailabilityConfigAndUserLoaded
                  ? 'navbar-logo-container--sub-page'
                  : ''
              } navbar-logo-container font-weight-bold text-black text-left`}
            >
              <div
                className="pointer"
                onClick={() => history.push('/')}
                data-testid="navbar-tenant-logo-homepage-pointer"
              >
                <img
                  src="/ci-logo.svg"
                  height="36"
                  alt="Cambridge Assessment International Education"
                />
              </div>
              {!sysAvailabilityConfigAndUserLoaded && (
                <div className="d-none d-lg-block site-heading">
                  Grade Submission System
                </div>
              )}
            </Col>

            <Col
              sm={sysAvailabilityConfigAndUserLoaded ? '10' : '6'}
              className="text-right"
            >
              <Nav
                className="ml-auto align-items-end align-items-md-center  justify-content-end"
                navbar
              >
                {sysAvailabilityConfigAndUserLoaded && (
                  <>
                    <NavItem className="d-none d-md-block mr-lg-3">
                      <NavLink
                        className="font-weight-bold text-secondary mr-5"
                        to="/help-and-contact"
                        data-testid="navbar-help-and-contact-link"
                      >
                        Help &amp; Contact
                      </NavLink>
                    </NavItem>
                    <UncontrolledDropdown nav inNavbar>
                      <DropdownToggle
                        nav
                        className="ml-3 p-0 nav-toggle"
                        data-testid="header-toggle"
                      >
                        <div className="d-inline-block align-middle">
                          <div className="font-weight-bold  text-color333">
                            {user?.roles.join(' | ')}
                          </div>
                          {currentCentre && (
                            <div className="text-secondary ">
                              {`Centre: ${currentCentre}`}
                            </div>
                          )}
                        </div>
                        <FontAwesomeIcon
                          className="d-inline-block ml-3 align-middle text-color333"
                          icon={faCaretDown}
                        />
                      </DropdownToggle>

                      <DropdownMenu right className="mt-3">
                        {user && isAdmin(user) && (
                          <>
                            {userManagamentAvailable && (
                              <>
                                <Link
                                  className="no-text-hover"
                                  to="/manage/teachers"
                                >
                                  <DropdownItem
                                    id="qaManageBtn"
                                    className="text-left py-3 font-weight-bold mr-4 "
                                  >
                                    <FontAwesomeIcon
                                      icon={faUsers}
                                      className="mr-3"
                                    />
                                    Manage Teachers
                                  </DropdownItem>
                                </Link>
                                <Link
                                  className="no-text-hover"
                                  to="/manage/signoff"
                                >
                                  <DropdownItem
                                    id="qaManageBtn"
                                    className="text-left py-3 font-weight-bold mr-4 "
                                  >
                                    <FontAwesomeIcon
                                      icon={faUserShield}
                                      className="mr-3"
                                    />
                                    Manage Head of Centre
                                  </DropdownItem>
                                </Link>
                              </>
                            )}

                            {userManagamentAvailable && (
                              <div className="separator" />
                            )}
                          </>
                        )}
                        <DropdownItem
                          id="qsLogoutBtn"
                          className="text-left text-redonblack py-3 font-weight-bold mr-4 "
                          onClick={() =>
                            logout({ returnTo: window.location.origin })
                          }
                          data-testid="logout-btn"
                        >
                          <FontAwesomeIcon icon={faPowerOff} className="mr-3" />
                          Log out
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </>
                )}
              </Nav>
            </Col>
          </Row>
        </Container>
      </Navbar>
    </div>
  )
}
