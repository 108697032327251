export type BackButtonData = {
  returnUrl: string
  text: string
}
export type BackButtonDataFn = (arg0: {
  roleCAAdmin: boolean
  params: { [key: string]: string }
}) => BackButtonData | null

const HOME: BackButtonData = {
  returnUrl: '/',
  text: 'Home',
}
export const NULL: BackButtonData = Object.freeze({
  returnUrl: 'NULL',
  text: 'NULL',
})

interface IDictionary {
  [key: string]: BackButtonDataFn | BackButtonData | null
}
export const DICTIONARY: IDictionary = {
  '/centres': () => {
    return NULL
  },
  '/centres/:id': ({ roleCAAdmin }) =>
    roleCAAdmin
      ? {
          returnUrl: '/centres',
          text: 'Home',
        }
      : NULL,
  '/centres/:id/syllabus/:syllabusId': ({ params }) => ({
    returnUrl: `/centres/${params.id}`,
    text: 'Dashboard',
  }),
  '/manage/teachers': HOME,
  '/manage/signoff': HOME,
  '/centres/:id/manage/teachers': HOME,
  '/centres/:id/manage/signoff': HOME,
  '/centres/:id/manage/admins': HOME,
  '/help-and-contact': HOME,

  DEFAULT: NULL,
}
