import React, { FC, useEffect, useMemo } from 'react'
import ReactGA from 'react-ga'
import { Button, ModalProps, Spinner } from 'reactstrap'
import {
  CentreSyllabus,
  getSubTitle,
  ProjectModal,
  RsModalBody,
  RsModalFooter,
  RsModalHeader,
  Title,
  useTemplateDownload,
} from '../../../common'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowToBottom, faCheck } from '@fortawesome/pro-solid-svg-icons'
import DownloadModalContent from './download-modal-content'

interface IDownloadModal {
  onClose: () => void
}

export const DownloadModal: FC<
  ModalProps & CentreSyllabus & IDownloadModal
> = ({
  id,
  syllabusCode,
  syllabusName,
  valuationStatus,
  qualLevel,
  dataSource,
  totalCandidates,
  centreId,
  isOpen,
  qualification,
  onClose,
}) => {
  const { start, result, error, downloadPending } = useTemplateDownload(
    centreId,
    id,
    syllabusCode
  )

  const subTitle = useMemo(() => {
    return getSubTitle(qualification, syllabusCode, qualLevel, dataSource)
  }, [qualification, qualLevel, syllabusCode, dataSource])

  useEffect(() => {
    if (result && isOpen) {
      onClose()
    }
  }, [result])

  const onDownloadHandler = () => {
    ReactGA.event({
      category: 'Grade Submission',
      action: 'Download Template',
      label: id,
    })
    start()
  }

  return (
    <ProjectModal
      centered
      isOpen={isOpen}
      toggle={!downloadPending ? onClose : undefined}
      dataTestid="download-modal"
    >
      <RsModalHeader
        toggle={!downloadPending ? onClose : undefined}
        className="bg-white px-5 pt-5 pb-25"
      >
        <Title
          title={syllabusName}
          subTitle={subTitle}
          ancillery={`${totalCandidates} candidates`}
        />
      </RsModalHeader>
      <RsModalBody className="px-5">
        <div className="font-weight-bold">Download Template</div>
        <DownloadModalContent
          valuationStatus={valuationStatus}
          showError={!!error}
        />
      </RsModalBody>
      <RsModalFooter className="px-5 pb-5">
        <Button
          className="ml-0"
          disabled={downloadPending}
          color="primary"
          onClick={onDownloadHandler}
          data-testid="syllabus-modal-csv-template-download-button"
        >
          {downloadPending ? (
            <Spinner className="mr-2" size="sm" />
          ) : (
            <FontAwesomeIcon
              className="mr-2"
              icon={result ? faCheck : faArrowToBottom}
            />
          )}
          Download
        </Button>
      </RsModalFooter>
    </ProjectModal>
  )
}
