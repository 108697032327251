import React from 'react'

interface Props {
  syllabusId: string
  gradedCandidates: number
  totalCandidates: number
}

export const CandidateStats: React.FC<Props> = ({
  syllabusId,
  gradedCandidates,
  totalCandidates,
}) => {
  return (
    <span className="d-md-inline-block text-secondary font-weight-semi-bold">
      <span
        data-testid={`candidate-progress-${syllabusId}`}
        className="mb-0 font-larger"
      >
        {gradedCandidates}
        {' / '}
        {totalCandidates}
      </span>
      <span>{' candidates completed'}</span>
    </span>
  )
}
