import React, { FC, useCallback, useState } from 'react'
import ReactGA from 'react-ga'
import { Button, Label, ModalProps, Progress } from 'reactstrap'
import { useDropzone } from 'react-dropzone'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { UploadFailed, UploadFinished, UploadStarted } from './subcomponents'
import {
  CentreSyllabus,
  getSubTitle,
  InlineErrorMessageNoBorder,
  ProjectModal,
  RsModalBody,
  RsModalFooter,
  RsModalHeader,
  Title,
  useTemplateUpload,
} from '../../common'
import { faArrowToTop } from '@fortawesome/pro-solid-svg-icons'

import './manage-upload-modal.scss'

export const ManageUploadModal: FC<
  ModalProps &
    CentreSyllabus & {
      onUploadComplete: (val: boolean) => void
      onClose: () => void
    }
> = ({
  onClose,
  onUploadComplete,
  id,
  syllabusCode,
  syllabusName,
  qualLevel,
  dataSource,
  totalCandidates,
  centreId,
  isOpen,
  qualification,
}) => {
  const [toUpload, setToUpload] = useState<File | null>(null)

  const {
    start,
    uploadResult,
    uploadProgressError,
    getUploadProgressTimedOut,
    uploadError,
    pending,
    loadedMax,
    loadedValue,
  } = useTemplateUpload(centreId, id, toUpload)

  const [lastUploadFileWasInvalid, setLastUploadFileWasInvalid] = useState(
    false
  )

  const onDrop = useCallback(
    (acceptedFiles: File[], rejectedFiles: File[]) => {
      const csvFile =
        acceptedFiles[0] &&
        acceptedFiles[0].name &&
        acceptedFiles[0].name.endsWith('csv')
          ? acceptedFiles[0]
          : null
      const fileValid = csvFile && rejectedFiles.length === 0

      setLastUploadFileWasInvalid(!fileValid)
      if (fileValid) {
        ReactGA.event({
          category: 'Grade Submission',
          action: 'Upload',
          label: id,
        })
        setToUpload(csvFile)
        start()
      }
    },
    [setToUpload, start, id]
  )

  const dropZone = useDropzone({
    onDrop: onDrop,
    accept: '.csv',
    noClick: true,
  })

  const setSubjectPageError = () => {
    onUploadComplete(uploadResult?.status === 'ERRONEOUS')
  }

  const closeModal = () => {
    if (uploadResult || uploadProgressError) {
      setSubjectPageError()
      return
    }
    onClose()
  }

  return (
    <ProjectModal
      centered
      isOpen={isOpen}
      className="manage-upload"
      dataTestid="manage-upload-modal"
    >
      <RsModalHeader
        toggle={(!pending && closeModal) || undefined}
        className="bg-white px-5 pt-5 pb-25"
      >
        <Title
          title={syllabusName}
          subTitle={getSubTitle(
            qualification,
            syllabusCode,
            qualLevel,
            dataSource
          )}
          ancillery={`${totalCandidates} candidates`}
        />
      </RsModalHeader>
      <RsModalBody className="px-5">
        <div
          {...dropZone.getRootProps({ tabIndex: -1 })}
          data-testid="dropzone"
        >
          <input {...dropZone.getInputProps()} />
          {!pending && !(uploadResult?.status === 'ERRONEOUS') && (
            <Label className="font-larger mb-1 modal-margin font-weight-bold">
              Upload a template
            </Label>
          )}
          {pending && (
            <UploadStarted fileName={dropZone.acceptedFiles[0].name} />
          )}
          {uploadResult && (
            <>
              {uploadResult.status === 'PROCESSED' && (
                <UploadFinished fileName={dropZone.acceptedFiles[0].name} />
              )}
              {uploadResult.status === 'ERRONEOUS' && (
                <UploadFailed
                  fileName={toUpload!.name}
                  errors={uploadResult.validationErrors}
                  expectedCandidates={totalCandidates}
                  updatedCandidatesCount={uploadResult.updatedItems}
                />
              )}
            </>
          )}

          {!pending && !uploadResult && !getUploadProgressTimedOut && (
            <div className="mt-0 modal-margin">
              <span>
                Your template must be in CSV format. Uploading a template will
                replace any information that’s already been entered for this
                syllabus.
              </span>
              <p style={{ textDecoration: 'underline' }} className="mt-4">
                Before uploading the template, make sure that no one is
                currently editing this page.
              </p>
            </div>
          )}
          {lastUploadFileWasInvalid && (
            <InlineErrorMessageNoBorder
              className="mt-4"
              title="You have selected an invalid file format. Please selected a CSV file."
            />
          )}
          {getUploadProgressTimedOut && (
            <InlineErrorMessageNoBorder
              className="mt-4"
              title="Your template has failed to upload. Please check your data and try again. If this problem persists, please contact us"
            />
          )}
          {uploadError && (
            <InlineErrorMessageNoBorder
              className="mt-4"
              title="Failed to upload, please try again and if the problem persists contact your system administrator."
            />
          )}
        </div>
      </RsModalBody>
      <RsModalFooter className="px-5 pb-5">
        {pending && (
          <Progress
            className=" mt-3 progress-slim-2 rounded-top-3 w-100 mx-0"
            max={loadedMax || 0}
            value={loadedValue || 0}
          />
        )}

        {!pending && !uploadResult && !getUploadProgressTimedOut && (
          <Button
            data-testid="modal-upload-btn"
            color="primary"
            className="ml-0"
            size="lg"
            onClick={() => dropZone.open()}
          >
            <FontAwesomeIcon
              color="white"
              icon={faArrowToTop}
              className="mr-2"
            />
            Select file to upload
          </Button>
        )}
        {!pending && uploadResult && !getUploadProgressTimedOut && (
          <Button
            color="primary"
            size="lg"
            className="ml-0"
            onClick={setSubjectPageError}
          >
            Continue
          </Button>
        )}
        {getUploadProgressTimedOut && (
          <Button
            color="primary"
            size="lg"
            className="ml-0"
            onClick={setSubjectPageError}
          >
            Go back
          </Button>
        )}
      </RsModalFooter>
    </ProjectModal>
  )
}
