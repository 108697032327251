import React from 'react'
import { AddUserEvents, ROLES, UserData } from '../../../common'

export const ADD_USER_MODAL_DATA_MAP = {
  [AddUserEvents.SAME_CENTRE_SAME_ROLE]: (data: UserData) => ({
    title: 'Adding an already existing user',
    description: (
      <div>
        This user already has the role of <b>{data.userRole}</b> at this centre
      </div>
    ),
    subDescription: (
      <div className="mt-1">
        You can resend the invitation in the user management page.
      </div>
    ),
    confirmText: null,
    cancelText: 'Cancel',
  }),
  [AddUserEvents.SAME_CENTRE_NEW_ROLE]: (data: UserData) => ({
    title: `Changing ${data.userRole} role to ${data.role}`,
    description: (
      <div>
        This user currently has the <b>{data.userRole}</b> role in this centre.
        {data.userRole === ROLES.HOC
          ? ' Without the Head of Centre role, you will not be able to submit grades to us.'
          : ''}
      </div>
    ),
    subDescription: (
      <u className="d-block">Please confirm if you wish to proceed.</u>
    ),
    confirmText: 'Yes, change role',
    cancelText: 'Cancel',
  }),
  [AddUserEvents.MULTIPLE_CENTRES_SAME_ROLE]: (data: UserData) => ({
    title: `Adding a new centre (${data.centreId}) for user`,
    description: (
      <div>
        This user already has the <b>{data.userRole}</b> role in the following{' '}
        centres: {(data.userCentres || []).join(', ')}.
      </div>
    ),
    subDescription: (
      <u className="d-block">Please confirm if you wish to proceed.</u>
    ),
    confirmText: 'Yes, add centre',
    cancelText: 'Cancel',
  }),
  [AddUserEvents.MULTIPLE_CENTRES_NEW_ROLE]: (data: UserData) => ({
    title: `Changing role to ${data.role} for user with multiple centres`,
    description: (
      <div>
        This user has the role <b>{data.userRole}</b> in the following centres:{' '}
        {(data.userCentres || []).join(', ')}.
      </div>
    ),
    subDescription: (
      <div className="mt-1">
        This user cannot have the role <b>{data.role} </b>
        assigned in selected centre (either delete and recreate all users with
        wanted role in all centres or contact support).
      </div>
    ),
    confirmText: null,
    cancelText: 'Cancel',
  }),
}
