import { faPen } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { Button, Col } from 'reactstrap'
import { ExtendDeadlineModal } from './extend-deadline-modal'

interface Props {
  header: string
  subheader?: string
  ancillery: string
  centreId: string
  syllabusId: string
  onUpdate: () => void
  deadline: Date
  extendedDeadline: Date
}

export const DeadlineButton: React.FC<Props> = ({
  header,
  subheader = '',
  ancillery,
  centreId,
  syllabusId,
  onUpdate,
  deadline,
  extendedDeadline,
}): JSX.Element => {
  const [openModal, setOpenModal] = useState<boolean>(false)

  return (
    <Col xs="auto" className="text-right pr-3">
      <Button
        className="deadline-button"
        color="success"
        onClick={(event) => {
          event.stopPropagation()
          event.preventDefault()
          setOpenModal(true)
        }}
        data-testid={`deadline-button-${syllabusId}`}
      >
        <FontAwesomeIcon className="mr-2" icon={faPen} />
        DEADLINE
      </Button>
      <ExtendDeadlineModal
        header={header}
        subheader={subheader}
        ancillery={ancillery}
        centreId={centreId}
        syllabusId={syllabusId}
        isOpen={openModal}
        setOpen={setOpenModal}
        onUpdate={onUpdate}
        deadline={deadline}
        extendedDeadline={extendedDeadline}
      />
    </Col>
  )
}
