import React, { forwardRef, useEffect, useMemo, useState } from 'react'
import { faCalendar } from '@fortawesome/free-regular-svg-icons'
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { addDays, isFuture } from 'date-fns'
import DatePicker from 'react-datepicker'
import { Button, Input, Spinner } from 'reactstrap'
import {
  adjustTimestampToUtc,
  convertToUTC,
  ProjectModal,
  RsModalBody,
  RsModalFooter,
  RsModalHeader,
  Title,
  useConfig,
  useExtendDeadline,
} from '../../../../../common'

import './extend-deadline-modal.scss'
import 'react-datepicker/dist/react-datepicker.css'

interface Props {
  header: string
  subheader: string
  ancillery: string
  centreId: string
  syllabusId: string
  isOpen: boolean
  setOpen: (val: boolean) => void
  onUpdate: () => void
  deadline: Date
  extendedDeadline: Date
}

export const ExtendDeadlineModal: React.FC<Props> = ({
  header,
  subheader,
  ancillery,
  centreId,
  syllabusId,
  isOpen,
  setOpen,
  onUpdate,
  deadline,
  extendedDeadline,
}) => {
  const [newDeadline, setNewDeadline] = useState<Date | null>(null)

  const { config } = useConfig()

  const {
    start,
    inProgress: saveInProgress,
    error,
    executed,
  } = useExtendDeadline(centreId, syllabusId, newDeadline)

  useEffect(() => {
    executed && onUpdate()
  }, [executed])

  const CustomInput = forwardRef(({ value, onClick }: any, ref: any) => {
    return (
      <div className="custom-input">
        <Input
          placeholder="Set date"
          onClick={onClick}
          ref={ref}
          value={value}
          data-testid="set-date-input"
        />
        <FontAwesomeIcon icon={faCalendar} />
      </div>
    )
  })

  const disabledButton = useMemo(() => newDeadline === null || saveInProgress, [
    newDeadline,
    saveInProgress,
  ])

  return (
    <ProjectModal
      className={`extend-deadline-modal remove-user-modal ${
        saveInProgress ? 'disabled' : ''
      }`}
      centered
      isOpen={isOpen}
      dataTestid="extend-deadline-modal"
    >
      <RsModalHeader
        toggle={() => !saveInProgress && setOpen(false)}
        className="bg-white px-5 pt-5 pb-25"
      >
        <Title title={header} subTitle={subheader} ancillery={ancillery} />
      </RsModalHeader>
      <RsModalBody className="pt-4 px-5 pb-5">
        <div>
          <p className="font-weight-bold font-larger">
            Extend deadline for this form
          </p>
          <p className="font-normal">
            Please add a new deadline. It will only apply to this form.
          </p>
          {error && (
            <div className="text-danger font-weigh-bold">
              <FontAwesomeIcon icon={faExclamationTriangle} className="mr-3" />
              <span>Failed to add a new deadline, please try again</span>
            </div>
          )}
          <DatePicker
            dateFormat="dd/MM/yyyy"
            selected={newDeadline ? newDeadline : extendedDeadline}
            onChange={(date) => setNewDeadline(convertToUTC(date as Date))}
            customInput={<CustomInput />}
            minDate={addDays(isFuture(deadline) ? deadline : new Date(), 1)}
            maxDate={
              config.captureAvailableTo
                ? adjustTimestampToUtc(config.captureAvailableTo.getTime())
                : new Date()
            }
            disabledKeyboardNavigation
          />
        </div>
      </RsModalBody>
      <RsModalFooter className="px-5 pb-5">
        <Button
          onClick={start}
          disabled={disabledButton}
          color="primary"
          data-testid="submit-extended-deadline-btn"
        >
          {saveInProgress && <Spinner size="sm" />}
          <span>Submit</span>
        </Button>
      </RsModalFooter>
    </ProjectModal>
  )
}
