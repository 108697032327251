import React from 'react'
import {
  Button,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Row,
} from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowRight,
  faBookOpen,
  faEnvelope,
  faPhone,
} from '@fortawesome/free-solid-svg-icons'
import { faShield } from '@fortawesome/pro-solid-svg-icons'
import ReactGA from 'react-ga'

export const HelpAndContact: React.FC = (): JSX.Element => {
  const helpGuideUri =
    'https://www.cambridgeinternational.org/covid/november-2021-exam-series/school-assessed-grades/'
  const resourcesFaqUri =
    'https://www.cambridgeinternational.org/covid/november-2021-exam-series/school-assessed-grades/'
  const privacyPolicyUri =
    'https://www.cambridgeinternational.org/privacy-and-legal/data-protection-and-privacy/'
  const tandcUri =
    'https://www.cambridgeinternational.org/privacy-and-legal/terms-and-conditions/'

  const supportMail = 'info@cambridgeinternational.org'

  return (
    <>
      <div className={`px-3 mb-5 bg-light py-45`}>
        <Container>
          <Row>
            <Col md={12}>
              <div className="h4 font-weight-bold text-primary">
                Help and Contact
              </div>
              <p>
                We have created a range of support to help you use the system,
                including a step-by-step 'How to guide', a video tutorial and
                FAQs.
              </p>
              <p>
                You can access this support on our public website using the link
                below or by visiting{' '}
                <ReactGA.OutboundLink
                  eventLabel={helpGuideUri}
                  to={helpGuideUri}
                  target="_blank"
                >
                  https://www.cambridgeinternational.org/covid/november-2021-exam-series/school-assessed-grades/
                </ReactGA.OutboundLink>
              </p>
              <ListGroup className="mt-4">
                <ListGroupItem className="border-0 bg-transparent px-0 py-1 font-weight-bold">
                  <ReactGA.OutboundLink
                    eventLabel={resourcesFaqUri}
                    to={resourcesFaqUri}
                    target="_blank"
                  >
                    <FontAwesomeIcon
                      icon={faArrowRight}
                      fixedWidth
                      className="mr-3"
                    />{' '}
                    <u>See support resources and FAQs</u>
                  </ReactGA.OutboundLink>{' '}
                </ListGroupItem>
                <ListGroupItem className="border-0 bg-transparent px-0 py-1  font-weight-bold">
                  <ReactGA.OutboundLink
                    eventLabel={privacyPolicyUri}
                    to={privacyPolicyUri}
                    target="_blank"
                  >
                    <FontAwesomeIcon
                      icon={faShield}
                      fixedWidth
                      className="mr-3"
                    />
                    Privacy Policy
                  </ReactGA.OutboundLink>
                </ListGroupItem>
                <ListGroupItem className="border-0 bg-transparent px-0 py-1  font-weight-bold">
                  <ReactGA.OutboundLink
                    eventLabel={tandcUri}
                    to={tandcUri}
                    target="_blank"
                  >
                    <FontAwesomeIcon
                      icon={faBookOpen}
                      fixedWidth
                      className="mr-3"
                    />
                    Terms and Conditions
                  </ReactGA.OutboundLink>
                </ListGroupItem>
              </ListGroup>
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        <p className="font-weight-bold mb-3">
          If you need to contact our Customer Services team,
        </p>
        <p>
          you can visit{' '}
          <ReactGA.OutboundLink
            eventLabel="https://www.cambridgeinternational.org/help"
            to="https://www.cambridgeinternational.org/help"
            target="_blank"
          >
            www.cambridgeinternational.org/help
          </ReactGA.OutboundLink>{' '}
          or use the contact details below.{' '}
        </p>

        <Row>
          <Col xs="auto">
            <Button
              tag="a"
              href={`mailto:${supportMail}`}
              color="lightButNotTooLight font-weight-bold text-secondary"
            >
              <FontAwesomeIcon icon={faEnvelope} />
              <div>{supportMail}</div>
            </Button>
          </Col>
          <Col xs="auto">
            <Button color="lightButNotTooLight font-weight-bold text-secondary">
              <FontAwesomeIcon icon={faPhone} />
              <div>Phone (+44 1223 553 554)</div>
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  )
}
