const getTimezone = (d: Date) => {
  const dtf = Intl.DateTimeFormat(undefined, { timeZoneName: 'short' })
  return (
    (dtf.formatToParts(d).find((part) => part.type === 'timeZoneName') || {})
      .value || ''
  )
}

export const formatDate = (date: Date, utc = false, full = false): string => {
  const dd = utc ? date.getUTCDate() : date.getDate()
  const mm = (utc ? date.getUTCMonth() : date.getMonth()) + 1
  const yyyy = utc ? date.getUTCFullYear() : date.getFullYear()
  const yy = ('' + yyyy).slice(2)

  const timezone = utc ? 'UTC' : getTimezone(date)
  const hh = utc ? date.getUTCHours() : date.getHours()
  const min = utc ? date.getUTCMinutes() : date.getMinutes()

  const part1 = `${dd < 10 ? '0' : ''}${dd}/${mm < 10 ? '0' : ''}${mm}/${
    full ? yyyy : yy
  }`
  const part2 = `${timezone ? ` ${timezone}` : ''} ${hh < 10 ? '0' : ''}${hh}:${
    min < 10 ? '0' : ''
  }${min}`

  return full ? part1 + part2 : part1
}

export const convertToUTC = (date: Date): Date => {
  const day = date.getDate()
  const month = date.getMonth()
  const year = date.getFullYear()
  return new Date(Date.UTC(year, month, day))
}

/*
  Note: this method changes date without changing time zone
  e.g. "Sat Jun 19 2021 02:59:00 GMT+0300" is changed to "Fri Jun 18 2021 23:59:00 GMT+0300"
  Date and time in the utc but time zone isn't changed
 */
export const adjustTimestampToUtc = (timestamp: number) => {
  const date = new Date(timestamp)
  const timeZone = date.getTimezoneOffset() / 60
  date.setHours(date.getHours() + timeZone)
  return date
}
