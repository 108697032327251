import React from 'react'
import { Col, Label } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons'
import { ValuationStatus } from '../../../common'

interface Props {
  syllabusId: string
  inProgress: boolean
  closed: boolean
  is: (status: string) => boolean
}

export const SyllabusProgressStatus: React.FC<Props> = ({
  syllabusId,
  inProgress,
  closed,
  is,
}) => {
  return (
    <>
      {is(ValuationStatus.SUBMITTED) && closed && (
        <Col xs="auto" className="text-right pr-3">
          <div
            className="syllabus-progress-notification text-uppercase border border-2 rounded
                  font-weight-bold px-3 py-2 text-primary border-black37"
            data-testid={`syllabus-progress-status-${syllabusId}`}
          >
            <Label className="black-color mb-0">
              <FontAwesomeIcon
                className="mr-2"
                size="sm"
                icon={faExclamationTriangle}
              />
              <span>INCOMPLETE</span>
            </Label>
          </div>
        </Col>
      )}
      {inProgress && (
        <Col xs="auto" className="text-right pr-3">
          <div
            className={`syllabus-progress-notification text-uppercase border border-2 rounded
                  font-weight-bold px-3 py-2 text-primary
                  ${
                    is(ValuationStatus.NOT_STARTED)
                      ? 'border-black37'
                      : 'border-primary37'
                  }
                  `}
            data-testid={`syllabus-progress-status-${syllabusId}`}
          >
            {is(ValuationStatus.NOT_STARTED) && !closed && (
              <span className="text-secondary">NOT STARTED</span>
            )}
            {is(ValuationStatus.INCOMPLETE) && !closed && (
              <span>IN PROGRESS</span>
            )}
            {is(ValuationStatus.COMPLETE) && !closed && <span>COMPLETED</span>}
            {inProgress && closed && (
              <Label className="black-color mb-0">
                <FontAwesomeIcon
                  className="mr-2"
                  size="sm"
                  icon={faExclamationTriangle}
                />
                <span>INCOMPLETE</span>
              </Label>
            )}
          </div>
        </Col>
      )}
    </>
  )
}
