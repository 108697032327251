import axios, { AxiosError, AxiosResponse } from 'axios'
import { useCallback, useMemo, useState } from 'react'
import {
  useAsyncRun,
  useAsyncTaskAxios,
  useAsyncTaskTimeout,
} from 'react-hooks-async'
import { PreSignedUploadUrl, UploadStatus } from '../types'
import { isNotFound } from '../services'

const MAX_RETRIES = 9
const POLL_INTERVAL_SECS = 4
const TICK_INTERVAL_SECS = 0.5
const EXPECTED_TIME_TO_PROCESS = 26

export const useTemplateUpload = (
  centreId: string,
  syllabusId: string,
  file: File | null
) => {
  const [loadedValue, setLoadedValue] = useState(0)
  const [loadedMax, setLoadedMax] = useState(0)
  const [pollRetries, setPollRetries] = useState(0)

  const presignedUrlMemo = useMemo(() => {
    return {
      url: `${process.env.REACT_APP_APIDOMAIN}/centres/${centreId}/syllabuses/${syllabusId}/uploadUrl`,
    }
  }, [centreId, syllabusId])

  const getPresignedUrlTask = useAsyncTaskAxios<
    AxiosResponse<PreSignedUploadUrl>
  >(axios, presignedUrlMemo)

  const uploadProgressMemo = useMemo(() => {
    return {
      url: `${process.env.REACT_APP_APIDOMAIN}/centres/${centreId}/syllabuses/${syllabusId}/uploads/${getPresignedUrlTask.result?.data.uuid}`,
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [syllabusId, centreId, getPresignedUrlTask.result, pollRetries])

  const getUploadProgressTask = useAsyncTaskAxios<AxiosResponse<UploadStatus>>(
    axios,
    uploadProgressMemo
  )

  const getProgressNotFound = useMemo(() => {
    if (!getUploadProgressTask.error) {
      return false
    }
    return isNotFound((getUploadProgressTask.error as AxiosError).response)
  }, [getUploadProgressTask.error])

  const getProgressProcessing = useMemo(
    () => getUploadProgressTask.result?.data.status === 'PROCESSING',
    [getUploadProgressTask.result]
  )

  const getUploadProgressTimedOut = useMemo(() => {
    return (
      (getProgressNotFound || getProgressProcessing) &&
      pollRetries === MAX_RETRIES
    )
  }, [pollRetries, getProgressNotFound, getProgressProcessing])

  const getProgressError = useMemo(() => {
    if (!getUploadProgressTask.error) {
      return false
    }
    return (
      !isNotFound((getUploadProgressTask.error as AxiosError).response) ||
      getUploadProgressTimedOut
    )
  }, [getUploadProgressTask.error, getUploadProgressTimedOut])

  const uploadFileMemo = useMemo(() => {
    return {
      method: 'PUT',
      url: getPresignedUrlTask.result?.data.url,
      data: file,
      headers: {
        'Content-Type': 'form-data',
      },
      onUploadProgress: (progressEvent: any) => {
        const { loaded, total } = progressEvent
        setLoadedMax(total * 2)
        setLoadedValue(loaded)
      },
    }
  }, [getPresignedUrlTask.result, file])

  const uploadFileTask = useAsyncTaskAxios<AxiosResponse>(axios, uploadFileMemo)
  useAsyncRun(getPresignedUrlTask.result && file && uploadFileTask)

  useAsyncRun(pollRetries > 0 && uploadFileTask.result && getUploadProgressTask)

  const tickItOver = useCallback(() => {
    setLoadedValue(
      Math.min(
        loadedMax / 2 / (EXPECTED_TIME_TO_PROCESS / TICK_INTERVAL_SECS) +
          loadedValue,
        loadedMax
      )
    )
  }, [loadedMax, loadedValue])

  const pollForProgress = useCallback(() => {
    if (getUploadProgressTask.started && getUploadProgressTask.pending) {
      return
    }
    if (!getUploadProgressTask.started && pollRetries === 0) {
      setPollRetries(1)
    }
    if (
      (getProgressNotFound ||
        getUploadProgressTask.result?.data.status === 'PROCESSING') &&
      pollRetries < MAX_RETRIES
    ) {
      setPollRetries(pollRetries + 1)
    }
  }, [
    uploadFileTask.result,
    pollRetries,
    getUploadProgressTask.started,
    getUploadProgressTask.result,
    getProgressNotFound,
    getUploadProgressTask.pending,
  ])

  const timer = useAsyncTaskTimeout(pollForProgress, POLL_INTERVAL_SECS * 1000)
  const tick = useAsyncTaskTimeout(tickItOver, TICK_INTERVAL_SECS * 1000)

  useAsyncRun(uploadFileTask.result && timer)
  useAsyncRun(uploadFileTask.result && tick)

  const pending = useMemo(() => {
    return (
      (getPresignedUrlTask.pending && getPresignedUrlTask.started) ||
      (uploadFileTask.pending && uploadFileTask.started) ||
      (uploadFileTask.result &&
        (!(getUploadProgressTask.result || getProgressError) ||
          (getProgressProcessing && !getUploadProgressTimedOut)))
    )
  }, [
    getPresignedUrlTask,
    uploadFileTask,
    getUploadProgressTask,
    getProgressProcessing,
    getProgressError,
    getUploadProgressTimedOut,
  ])

  return {
    start: getPresignedUrlTask.start,
    uploadResult: getUploadProgressTask.result?.data,
    uploadProgressError: getUploadProgressTask.error,
    getUploadProgressTimedOut,
    uploadError:
      getPresignedUrlTask.error ||
      uploadFileTask.error ||
      (!getUploadProgressTimedOut && getProgressError),
    pending,
    loadedMax,
    loadedValue,
  }
}

export default useTemplateUpload
