import React from 'react'
import { ROLES, withCentreId, withExpiration } from '../../common'
import { ManagementPage } from './management-page'

let INFO_DESCRIPTION = <></>

if (process.env.REACT_APP_BU === 'ci') {
  INFO_DESCRIPTION = (
    <>
      The <b>Teacher</b> user role can enter and upload school-assessed grades
      and send them to the Head of Centre for approval.
      <br />
      Please enter your staff members' details. Teachers will receive a
      confirmation email with a verification link. Teachers must click the link
      to gain access to the system. Teachers will not be able to enter or upload
      school-assessed grades until they have done this.
    </>
  )
}

export const ManageTeachersPage: React.FC<{ centreId: string }> = ({
  centreId,
}): JSX.Element => (
  <ManagementPage
    centreId={centreId}
    title="Add new Teachers"
    description={INFO_DESCRIPTION}
    buttonText="Add teacher"
    emptyListMessage="There are currently no teachers at this centre"
    role={ROLES.Teacher}
    currentUsersLabel="Current teachers"
  />
)

export const ManageTeachersPageWithRoute = withCentreId(ManageTeachersPage)
export const ManageTeachersPageWithExpiration = withExpiration(
  ManageTeachersPage
)
export const ManageTeachersPageWithRouteAndExpiration = withExpiration(
  ManageTeachersPageWithRoute
)
