import React from 'react'
import { Label } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { ProjectModal, RsModalBody, RsModalHeader } from '../../../common'
import { IChangeRequest } from '../types'

interface Props {
  isOpen: boolean
  onClose: () => void
  changeRequest: IChangeRequest
  changeGrade: () => void
}

export const ChangingGradeModal: React.FC<Props> = ({
  isOpen,
  onClose,
  changeRequest,
  changeGrade,
}) => {
  return (
    <ProjectModal
      className="candidate-grade-change-modal"
      centered
      isOpen={isOpen}
    >
      <RsModalHeader toggle={onClose} className="bg-white px-5 pt-5 pb-0">
        <h4
          className="font-weight-bold text-primary mb-0"
          style={{ fontSize: '22px' }}
        >
          Changing grade for a candidate
        </h4>
        <h5 className="mb-0 mt-25">
          <span className="font-weight-bold">
            {changeRequest.candidate?.name}
          </span>
          <span className="ml-1">{`(${changeRequest.candidate.candidateNumber})`}</span>
        </h5>
      </RsModalHeader>
      <RsModalBody className="pt-0 px-5 pb-5">
        <div className="mt-4">
          <Label
            className="mb-0 candidate-grade d-inline-flex justify-content-center align-items-center"
            style={{ width: '3rem', height: '3rem' }}
          >
            {changeRequest.candidate?.grade}
          </Label>
          <FontAwesomeIcon
            className="mx-42 d-inline-flex align-items-center"
            style={{ width: '20px', height: '20px' }}
            size="2x"
            icon={faArrowRight}
          />
          <Label
            className="mb-0 candidate-grade d-inline-flex justify-content-center align-items-center"
            style={{ width: '3rem', height: '3rem' }}
          >
            {changeRequest.update?.grade}
          </Label>
        </div>
        <Label className="mb-0 mt-4 font-larger" style={{ maxWidth: '90%' }}>
          You are trying to change this candidate's grade.
        </Label>
        <div className="mt-55">
          <Label
            data-testid="grade-change-cancel"
            className="bg-lightButNotTooLight text-secondary font-weight-bold rounded px-35 py-25 mb-0 pointer"
            onClick={onClose}
          >
            Cancel
          </Label>
          <Label
            data-testid="grade-change-continue"
            className="bg-primary text-white font-weight-bold rounded px-35 py-25 ml-3 mb-0 pointer"
            onClick={changeGrade}
          >
            Continue
          </Label>
        </div>
      </RsModalBody>
    </ProjectModal>
  )
}
