import React, { useEffect, useMemo } from 'react'
import ReactGA from 'react-ga'
import axios, { AxiosResponse } from 'axios'
import { Button, Spinner } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faPaperPlane } from '@fortawesome/pro-solid-svg-icons'
import { useAsyncTaskAxios } from 'react-hooks-async'

interface Props {
  centreId: string
  email: string
  userId?: string
}

const ResendInviteButton: React.FC<Props> = ({ centreId, email, userId }) => {
  const resendInviteMemo = useMemo(() => {
    return {
      url: `${process.env.REACT_APP_APIDOMAIN}/centres/${centreId}/sendWelcomeMail`,
      method: 'POST',
      data: {
        email: email,
      },
    }
  }, [email, centreId])

  const resendInviteTask = useAsyncTaskAxios<AxiosResponse<void>>(
    axios,
    resendInviteMemo
  )

  useEffect(() => {
    if (resendInviteTask.error) {
      ReactGA.exception({
        description: `Failed to resend invite - ${userId}`,
        fatal: false,
      })
    }
  }, [resendInviteTask.error, userId])

  const inProgress = resendInviteTask.pending && resendInviteTask.started

  return (
    <Button
      size="sm"
      color="link"
      disabled={inProgress || resendInviteTask.result !== null}
      className=" font-smaller px-3 py-2"
      onClick={() => {
        ReactGA.event({
          category: 'User',
          action: 'Resend Invite',
          label: userId,
        })
        resendInviteTask.start()
      }}
    >
      {inProgress && (
        <Spinner data-testid="resendSpinner" className="mr-md-2" size="sm" />
      )}
      {!inProgress && (
        <FontAwesomeIcon
          title={
            resendInviteTask.result
              ? 'Invite has been sent'
              : resendInviteTask.error
              ? 'Resend failed, click to try again'
              : 'Click to resend invite'
          }
          icon={resendInviteTask.result ? faCheck : faPaperPlane}
          className={`mr-md-2 ${resendInviteTask.error ? 'text-danger' : ''}`}
        />
      )}

      {resendInviteTask.result === null && (
        <span
          className={`d-none d-md-inline-block  ${
            resendInviteTask.error ? 'text-danger' : ''
          }`}
        >
          Resend invite
        </span>
      )}
      {resendInviteTask.result && (
        <span className="d-none d-md-inline-block">Invite sent</span>
      )}
    </Button>
  )
}

export default ResendInviteButton
