import React, { useMemo } from 'react'
import { Button, Col, Row, Spinner } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons'
import {
  AddUserEvents,
  ProjectModal,
  RsModalBody,
  RsModalHeader,
  Title,
  UserData,
} from '../../../common'
import { ADD_USER_MODAL_DATA_MAP } from './add-user-modal-data-map'

interface Props {
  show: boolean
  onClose: () => void
  onSubmit: () => void
  addUserInProgress: boolean
  showError: boolean
  userAddEvent: AddUserEvents
  userData?: UserData
}

export const AddUserModal: React.FC<Props> = ({
  show,
  onClose,
  onSubmit,
  userAddEvent,
  addUserInProgress,
  showError,
  userData,
}) => {
  const { name, surname, email } = userData || {}
  const textMap = useMemo(
    () => userData && ADD_USER_MODAL_DATA_MAP[userAddEvent](userData),
    [userAddEvent, userData]
  )

  return (
    <ProjectModal
      className={`remove-user-modal ${addUserInProgress ? 'disabled' : ''}`}
      centered
      isOpen={show}
      dataTestid="add-user-modal"
    >
      <RsModalHeader
        toggle={() => !addUserInProgress && onClose()}
        className="bg-white px-5 pt-5 pb-25"
      >
        <Title
          title={`${name || ''} ${surname || ''}`}
          subTitle={email || ''}
        />
      </RsModalHeader>
      <RsModalBody className="pt-4 px-5 pb-5 rounded-bottom">
        <div>
          <p className="font-weight-bold font-larger">{textMap?.title}</p>
          <p className="font-normal">
            {textMap?.description}
            {textMap?.subDescription}
          </p>
          {showError && (
            <div className="text-danger font-weigh-bold">
              <FontAwesomeIcon icon={faExclamationTriangle} className="mr-3" />
              <span>Failed to add user, please try again</span>
            </div>
          )}
          <Row className="mt-5 ">
            {textMap?.cancelText && (
              <Col xs="auto">
                <Button
                  color="e4"
                  disabled={addUserInProgress}
                  onClick={onClose}
                >
                  {textMap.cancelText}
                </Button>
              </Col>
            )}
            {textMap?.confirmText !== null && (
              <Col>
                <Button
                  onClick={() => {
                    onSubmit()
                    onClose()
                  }}
                  disabled={addUserInProgress}
                  color="primary"
                >
                  {addUserInProgress ? (
                    <Spinner size="sm" className="mr" />
                  ) : (
                    <span>{textMap?.confirmText}</span>
                  )}
                </Button>
              </Col>
            )}
          </Row>
        </div>
      </RsModalBody>
    </ProjectModal>
  )
}
