import React, { useMemo } from 'react'
import { Card, CardBody, CardFooter, Col, Progress, Row } from 'reactstrap'
import { Link } from 'react-router-dom'
import {
  CentreSyllabus,
  getSubTitle,
  syllabusInProgress,
  useAuth0,
  ValuationStatus,
  Title,
} from '../../common'
import { CandidateStats, StatusAndControlBlock } from './components'

import './syllabus-progress.scss'

interface Props {
  onUpdate?: () => void
}

export const SyllabusProgress: React.FC<
  Props & CentreSyllabus & React.HTMLAttributes<HTMLDivElement>
> = ({
  className,
  id,
  syllabusName,
  syllabusCode,
  qualification,
  qualLevel,
  totalCandidates,
  gradedCandidates,
  centreId,
  valuationStatus,
  percentageCompleted,
  closed,
  dataSource,
  onUpdate,
  deadline,
  extendedDeadline,
}): JSX.Element => {
  const inProgress = syllabusInProgress(valuationStatus)
  const is = useMemo(() => (status: string) => valuationStatus === status, [
    valuationStatus,
  ])
  const { user } = useAuth0()

  const syllabusTemplateFileName = useMemo(
    () => `${centreId}_${syllabusCode}.csv`,
    [centreId, qualification, syllabusCode]
  )

  const title = useMemo(() => syllabusName || syllabusCode, [
    syllabusName,
    syllabusCode,
  ])
  const subTitle = useMemo(
    () => getSubTitle(qualification, syllabusCode, qualLevel, dataSource),
    [qualLevel, qualification, syllabusCode, dataSource]
  )

  return (
    <div className="syllabus-progress">
      <Link
        className="no-text-hover"
        to={`/centres/${centreId}/syllabus/${id}`}
        data-testid={`syllabus-progress-${id}`}
      >
        <Card
          className={`${className} project-row transition-none`}
          data-testid="syllabus-card"
        >
          <CardBody
            className={`p-42 ${inProgress ? '' : 'bg-lightButNotTooLight'}`}
          >
            <Row className="align-items-center justify-content-between mb-4">
              <Col>
                <Title title={title} subTitle={subTitle} />
              </Col>
              <Col xs="auto" className="text-right">
                <CandidateStats
                  syllabusId={id}
                  gradedCandidates={gradedCandidates}
                  totalCandidates={totalCandidates}
                />
              </Col>
            </Row>
            <Row className="align-items-center justify-content-start">
              <StatusAndControlBlock
                title={title}
                subTitle={subTitle}
                syllabusId={id}
                centreId={centreId}
                totalCandidates={totalCandidates}
                gradedCandidates={gradedCandidates}
                inProgress={inProgress}
                user={user}
                syllabusTemplateFileName={syllabusTemplateFileName}
                closed={closed}
                is={is}
                onUpdate={onUpdate}
                deadline={deadline}
                extendedDeadline={extendedDeadline}
              />
            </Row>
          </CardBody>
          {(is(ValuationStatus.INCOMPLETE) || is(ValuationStatus.COMPLETE)) && (
            <CardFooter className="p-0 border-0">
              <Progress
                className="progress-slim-1 rounded-top-0 rounded-bottom-sm-down"
                max={100 || 0}
                value={percentageCompleted || 0}
              />
            </CardFooter>
          )}
        </Card>
      </Link>
    </div>
  )
}
